import React from 'react'
import {Helmet} from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import RichText from '../components/rich-text'
import Tags from '../components/tags'
import {graphql} from 'gatsby'
import {Disqus} from 'gatsby-plugin-disqus';
import References from "../components/references";

const CreateDate = ({date}) => <p style={{display: 'block',}}>{date}</p>
const Title = ({title}) => <h1 className="section-headline">{title}</h1>

const ComingSoon = () => <div className="coming-soon">
	<p>Coming soon!</p>
	<p>(maybe let me know you're interested, if you're a bit impatient)</p>
</div>

const DisqusComments = ({id, title, url}) => {
	if (!url || !id || !title) throw new Error(`discus needs all these props to be set
	id=${id}
	title=${title}
	url=${url}
	`)
	/* Page contents */
	return <Disqus
		config={{
			/* Replace PAGE_URL with your post's canonical URL variable */
			url: url,
			/* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
			identifier: id,
			/* Replace PAGE_TITLE with the title of the page */
			title: title,
		}}
	/>
};

class PostTemplate extends React.Component {
	render() {
		const post = get(this.props, 'data.contentfulEverpost')
		const siteTitle = get(this.props, 'data.site.siteMetadata.title')
		const isPublished = (post.tags || []).find(({name}) => name === 'published')
		const title = `${post.title} | ${siteTitle}`;
		return (
			<Layout location={this.props.location} title={title}>
				<div className="mainArticle">
					<div className="wrapper">
						<Title title={post.title}/>
						<Tags tags={post.tags}/>
						<CreateDate date={post.createdAt}/>
						{isPublished
							? <RichText content={post.content}/>
							: <ComingSoon/>
						}
						<References references={post.references}/>
						<DisqusComments id={post.id} title={post.title} url={`https://tjenwellens.eu/everblog/${post.id}`}/>
					</div>
				</div>

			</Layout>
		)
	}
}

export default PostTemplate

export const pageQuery = graphql`
query EverblogById($id: String!) {
  contentfulEverpost(contentful_id: { eq: $id }) {
    id: contentful_id
    title
    createdAt: created(formatString: "MMMM Do, YYYY")
    content {
      raw
      references {
        ... on ContentfulEverpost {
					# __typename is required to resolve the references
				  __typename
					# contentful_id is required to resolve the references
					contentful_id
				}
				... on ContentfulAsset {
					# __typename is required to resolve the references
				  __typename
					# contentful_id is required to resolve the references
					contentful_id
					file {
            url
          }
				}
			}
    }
    tags {
			id: contentful_id
			name
		}
		references: everpost {
			title
			id: contentful_id
		}
  }
  site {
    siteMetadata {
      title
    }
  }
}
`
