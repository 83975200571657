import React from "react";
import {Link} from "gatsby";

const EverpostLink = ({post}) => <Link to={`/everblog/${post.id}`} title={post.title}>{post.title}</Link>

const References = ({references}) => {
	if (!references) return <div/>

	return <div>
		<p>
			This post was referenced in:
			<ul>
				{(references || []).map(post => <li key={post.id}><EverpostLink post={post}/></li>)}
			</ul>
		</p>
	</div>
}

export default References
